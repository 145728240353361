import {observable, action, computed} from 'mobx';
import _ from 'lodash';
import {auth} from 'shared/core';
import employeeTimeOffStore from 'stores/time_off/EmployeeTimeOffStore';
import moment from 'moment';

class EmployeeTimeOffState {
  store = employeeTimeOffStore;
  parentState;
  history;
  employeeId;
  modules = {
    'moment-timezone': null
  };

  @observable upcomingHolidaysModalOpen = false;
  @observable accounts = [];
  @observable upcomingHolidays = [];
  @observable activeRequest = null;
  @observable notesModalOpen = false;
  @observable policy;
  @observable policySummaryModalOpen = false;
  @observable unlimitedPolicySummaryModalOpen = false;
  @observable policySummaryAccount;
  @observable agent;
  @observable editingManualAdjustment = {};
  @observable selectedTimeOffRequest = {};
  @observable errors = {};
  @observable transactionsForecastDate = moment();

  receiveProps({parentState, history}) {
    this.parentState = parentState;
    this.employeeId = parentState.match.params.id;
    this.history = history;
  }

  async load() {
    const [, module] = await Promise.all([
      this.store.load(this.employeeId),
      import(/* webpackChunkName: 'moment-timezone-async' */ 'moment-timezone')
    ]);

    this.modules['moment-timezone'] = module;
    this.accounts = this.store.getAccounts();
    this.policy = this.store.getPolicy();
    this.upcomingHolidays = this.store.getUpcomingHolidays();
    this.employee = this.store.getEmployeeDetails();
  }

  @action requestTimeOff() {
    this.history.push(`time-off/request`);
  }

  @computed get currentTypePolicy() {
    return _.find(this.policy.typePolicies, {
      type: { id: this.policySummaryAccount.timeOffType.id }
    });
  }

  @action openUpcomingHolidaysModal() {
    this.upcomingHolidaysModalOpen = true;
  }

  @action closeUpcomingHolidaysModal() {
    this.upcomingHolidaysModalOpen = false;
  }

  @action showNotesModal(request) {
    this.activeRequest = request;
    this.notesModalOpen = true;
  }

  @action closeNotesModal() {
    this.notesModalOpen = false;
  }

  @action setInteractiveAgent(agent) {
    this.agent = agent;
  }

  @action openPolicySummaryModal(policySummaryAccount) {
    this.policySummaryAccount = policySummaryAccount;

    if (policySummaryAccount.unlimited) {
      this.unlimitedPolicySummaryModalOpen = true;
    } else {
      this.policySummaryModalOpen = true;
    }
  }

  @action closePolicySummaryModals() {
    this.policySummaryModalOpen = false;
    this.unlimitedPolicySummaryModalOpen = false;
  }

  @action redirectToEdit(request) {
    this.history.push(`/${this.employeeId}/time-off/request/${request.id}`);
  }

  @action updateTransactionsForecastDate(date) {
    this.transactionsForecastDate = date || moment();
  }

  @action async deleteManualAdjustment() {
    await this.store.destroy(this.editingManualAdjustment);
    this.closeRemoveManualAdjustmentModal();
    this.agent.refresh();
  }

  @action async deleteTimeOffRequest() {
    await this.store.destroy(this.selectedTimeOffRequest);
    this.closeRemoveTimeOffRequestModal();
    this.agent.refresh();
  }

  @action async saveManualAdjustment() {
    const {model, errors} = await this.store.patch(this.editingManualAdjustment);

    this.errors = errors;

    if (model) {
      this.closeEditManualAdjustmentModal();
      this.agent.refresh();
    }
  }

  @computed get showRequestTimeOffButton() {
    return auth.employee.id === this.employeeId &&
      !this.parentState.employee.terminated;
  }

  @computed get showRecordTimeOffButton() {
    if (this.showRequestTimeOffButton) return false;
    return auth.hasAccess('::MANAGE_TIME_OFF') ||
      this.parentState.editDataPermission('::TIME_OFF');
  }

  @computed get currentEmployee() {
    return this.parentState.employee;
  }

  @computed get accountFilterOptions() {
    return this.accounts.map(account => ({
      id: account.id,
      display: account.timeOffType.name
    }));
  }

  @computed get timeOffEditable() {
    return !this.parentState.employee.terminated && auth.hasAccess('::MANAGE_TIME_OFF');
  }

  customLinksFor(model) {
    switch (model.transactionType) {
      case 'manual_adjustment_transaction':
        return this.customLinksForManualAdjustment(model);
      case 'absence_transaction':
        return this.customLinksForAbsence(model);
      default:
        return [];
    }
  }

  customLinksForManualAdjustment(model) {
    const links = [];

    if (model.canPatch) {
      links.push(
        {
          order: 0,
          text: 'Edit',
          action: () => this.openEditManualAdjustmentModal(model)
        }
      );
    }

    if (model.canDelete) {
      links.push(
        {
          order: 2,
          text: 'Delete',
          action: () => this.openRemoveManualAdjustmentModal(model)
        }
      );
    }

    return links;
  }

  customLinksForAbsence(model) {
    const links = [
      {
        order: 0,
        text: 'View',
        action: () => this.redirectToEdit(model.timeOffRequest)
      }
    ];

    if (model.timeOffRequest.canPatch) {
      links.push(
        {
          order: 1,
          text: 'Edit',
          action: () => this.redirectToEdit(model.timeOffRequest)
        }
      );
    }

    if (model.timeOffRequest.canDelete) {
      links.push(
        {
          order: 2,
          text: 'Delete',
          action: () => this.openRemoveTimeOffRequestModal(model.timeOffRequest)
        }
      );
    }

    return links;
  }
}

export default EmployeeTimeOffState;
