import {observable, action, computed} from 'mobx';
import {DomainStore, fetchModels} from 'shared/store';
import {types, endpoints, api, t, auth} from 'shared/core';
import {TimeOffRequest, ForecastedAccountBalance, TimeOffPolicy, TimeOffBlockedDates} from 'stores/time_off';
import DayOffViewModel from 'stores/time_off/DayOffViewModel';
import _ from 'lodash';
import {successAlert, rangeDate, dateToJson} from 'shared/tools';
import moment from 'moment';

class RequestTimeOffContainerState {
  store = new DomainStore();
  history;
  match;

  @observable employee;
  @observable types = [];
  @observable typePolicies = [];
  @observable request = new TimeOffRequest();
  @observable errors = {};
  @observable forecastingAccount = false;
  @observable forecastedBalance = {};
  @observable fetchingRequestedDays = false;
  @observable fetchingConcurrentRequests = false;
  @observable daysOffJson = [];
  @observable specifyCustomHours = false;
  @observable dayOffModalOpen = false;
  @observable editDayOff = null;
  @observable delegateUsers;
  @observable conflictingDates = [];
  @observable concurrentRequests = [];
  @observable allowedToDelegate;
  @observable blockedDates = [];
  @observable fetchingBlockedDates = false;

  receiveProps({employee, history, match}) {
    this.employee = employee;
    this.history = history;
    this.match = match;
  }

  @action async load() {
    const compose = [
      endpoints.TIME_OFF.POLICY.withEmployeeId(this.employee.id),
      endpoints.USERS.ACTIVE
    ];

    if (this.match.params.id) {
      compose.push(endpoints.TIME_OFF.REQUEST.with(this.match.params.id));
    }

    const composed = await this.store._compose(compose);

    const policy = new TimeOffPolicy(composed[0].data);
    this.types = _.map(policy.typePolicies, 'type');
    this.typePolicies = policy.typePolicies;

    this.delegateUsers = this.store.getUsers();

    let request = await api.get(endpoints.TIME_OFF.ALLOWED_TO_DELEGATE.with(this.employee.id));
    this.allowedToDelegate = request.data;

    if (this.match.params.id) {
      await this.initExistingRequest();
    } else {
      await this.initNewRequest();
    }

    this.forecastAccount();
    this.fetchConcurrent();
    this.fetchBlockedDates();
  }

  async initExistingRequest() {
    this.request = new TimeOffRequest(
      this.store._getSingle(types.TIME_OFF.REQUEST)
    );
    const response = await api.get(
      endpoints.TIME_OFF.DAYS_OFF.FOR_REQUEST.with({
        employeeId: this.request.employee.id,
        id: this.request.id,
      })
    );

    await this.fetchRequestedDays();
    for (let i = 0; i < this.daysOff.length; i++) {
      if (Number(this.daysOff[i].json.cost) !== Number(response.data[i].cost)) {
        this.specifyCustomHours = true;
      }
      this.daysOff[i].updateHours(
        response.data[i].cost,
        response.data[i].start_time ? moment(response.data[i].start_time).format(t('components.dates.TIMESTAMP_FORMAT')) : null,
        response.data[i].end_time ? moment(response.data[i].end_time).format(t('components.dates.TIMESTAMP_FORMAT')) : null
      );
    }
  }

  @action updateStartDate(newStartDate) {
    this.request.merge({startDate: newStartDate});
    this.forecastAccount();
    this.fetchRequestedDays();
    this.fetchConcurrent();
    this.fetchBlockedDates();
  }

  @action updateEndDate(newEndDate) {
    this.request.merge({endDate: newEndDate});
    this.fetchRequestedDays();
    this.fetchConcurrent();
    this.fetchBlockedDates();
  }

  @action updateTimeOffType(newTimeOffType) {
    this.request.merge({timeOffType: newTimeOffType});
    this.conflictingDates = [];
    this.forecastAccount();
    this.fetchBlockedDates();
  }

  @action async initNewRequest() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    this.request.timeOffType = this.sortedTypes[0];
    this.request.merge({
      startDate: today,
      endDate: today
    });
    await this.fetchRequestedDays();
  }

  @action async fetchConcurrent() {
    if (!this.request.hasValidDates) {
      return;
    }
    this.fetchingConcurrentRequests = true;

    this.concurrentRequests = await fetchModels(
      endpoints.TIME_OFF.CONCURRENT.FOR_POLICY_HOLDER.with({
        employeeId: this.employee.id,
        startDate: dateToJson(this.request.startDate),
        endDate: dateToJson(this.request.endDate)
      }),
      types.TIME_OFF.REQUEST
    );
    this.concurrentRequests = _.reject(this.concurrentRequests, { id: this.request.id });

    this.fetchingConcurrentRequests = false;
  }

  @action async forecastAccount() {
    if (!this.request.hasValidDates) {
      return;
    }

    this.forecastingAccount = true;

    const response = await api.get(
      this.request.isNew ?
        endpoints.TIME_OFF.FORECAST_ACCOUNT_BALANCE.with({
          employeeId: this.employee.id,
          typeId: this.request.timeOffType.id,
          date: dateToJson(this.request.startDate)
        }) :
        endpoints.TIME_OFF.FORECAST_ACCOUNT_BALANCE.FOR_REQUEST.with({
          employeeId: this.employee.id,
          typeId: this.request.timeOffType.id,
          date: dateToJson(this.request.startDate),
          id: this.request.id
        })
    );

    const balanceData = this.request.isNew ? response.data.balance : response.data.balance_before;

    this.forecastedBalance = new ForecastedAccountBalance({
      ...balanceData,
      workDayLength: response.data.work_day_length,
      unlimited: response.data.unlimited
    });

    this.forecastingAccount = false;
  }

  @action async fetchRequestedDays() {
    this.conflictingDates = [];

    if (!this.request.hasValidDates) {
      return;
    }

    if (this.request.endDate < this.request.startDate) {
      this.request.merge({endDate: this.request.startDate});
    }

    if (moment(this.request.endDate) > moment(this.request.startDate).add(1, 'y').subtract(1, 'd')) {
      this.request.merge({endDate: moment(this.request.startDate).add(1, 'y').subtract(1, 'd').toDate()});
    }

    this.fetchingRequestedDays = true;

    const response = await api.get(
      endpoints.TIME_OFF.DAYS_OFF.with({
        employeeId: this.employee.id,
        typeId: this.request.timeOffType.id,
        startDate: dateToJson(this.request.startDate),
        endDate: dateToJson(this.request.endDate)
      })
    );

    this.daysOffJson = response.data;
    this.daysOffJson.forEach(day => {
      day.start_time = null;
      day.end_time = null;
    });

    this.fetchingRequestedDays = false;
  }

  @action async fetchBlockedDates() {
    if (!this.request.hasValidDates) return;

    this.fetchingBlockedDates = true;

    const blockedDates = await fetchModels(
      endpoints.TIME_OFF.BLOCKED_DATES.FOR_REQUEST.with({
        employeeId: this.employee.id,
        typeId: this.request.timeOffType.id,
        startDate: dateToJson(this.request.startDate),
        endDate: dateToJson(this.request.endDate)
      }),
      types.TIME_OFF.BLOCKED_DATES
    );
    this.blockedDates = blockedDates.map(model => new TimeOffBlockedDates(model));

    this.fetchingBlockedDates = false;
  }

  @action toggleSpecifyCustomHours() {
    this.specifyCustomHours = !this.specifyCustomHours;
  }

  @action editHoursFor(dayOff) {
    if (!dayOff.canUpdateHours) return;

    this.editDayOff = new DayOffViewModel(_.cloneDeep(dayOff.json));
    this.dayOffModalOpen = true;
  }

  @action closeDayOffModal() {
    this.dayOffModalOpen = false;
  }

  @action updateCustomHours() {
    if (this.editDayOff.errorMessage) {
      this.editDayOff.showErrorMessage = true;
      return;
    }

    if (this.editDayOff.unit === 'full') {
      this.editDayOff.json.start_time = null;
      this.editDayOff.json.end_time = null;
    }

    const originalDayOff = _.find(this.daysOff, dayOff => dayOff.equalTo(this.editDayOff));
    _.merge(originalDayOff.json, this.editDayOff.json);
    originalDayOff.unit = this.editDayOff.unit;
    originalDayOff.json.cost = this.editDayOff.hoursExact;
    this.closeDayOffModal();
  }

  @action async submitRequest() {
    this.request.daysOff = this.daysOffJson;
    this.request.customHours = this.specifyCustomHours;
    this.request.daysOff.forEach(day => {
      if (day.start_time) {
        day.start_time = moment(`${day.date} ${day.start_time}`, ['YYYY-MM-DD h:m a', 'H:m']);
        day.end_time = moment(`${day.date} ${day.end_time}`, ['YYYY-MM-DD h:m a', 'H:m']);
      }
    });

    const {model, errors} = await this.store.post(
      endpoints.TIME_OFF.CONFLICTING_REQUESTS.with(this.employee.id),
      types.TIME_OFF.CONFLICT,
      {
        startDate: this.request.startDate,
        endDate: this.request.endDate,
        daysOff: this.daysOffJson,
        typeId: this.request.timeOffType.id,
        excludedRequestId: this.request.id
      }
    );
    this.errors = errors;

    if(model) {
      this.conflictingDates = model.dates;

      if (this.conflictingDates.length > 0) {
        return;
      }

      if (this.request.isNew) {
        const response = await this.store.post(
          endpoints.TIME_OFF.NEW_TIME_OFF_REQUEST.with({employeeId: this.employee.id}),
          types.TIME_OFF.REQUEST,
          this.request
        );

        this.errors = response.errors;

        if (response.model) {
          this.showSuccessAlert();
          this.history.push(`/${this.employee.id}/time-off`);
        }
      } else {
        const {model, errors} = await this.store.patch(this.request);

        this.errors = errors;

        if (model) {
          successAlert(t('employees.profile.time_off.request.REQUEST_UPDATED'));
          this.history.push(`/${this.employee.id}/time-off`);
        }
      }
    }
  }

  showSuccessAlert() {
    if (moment(this.request.startDate).isSame(this.request.endDate, 'day')) {
      successAlert(
        t('employees.profile.time_off.request.REQUESTED_TIME_OFF_ON_DATE', {
          date: rangeDate(this.request.startDate)
        })
      );
    } else {
      successAlert(
        t('employees.profile.time_off.request.REQUESTED_TIME_OFF', {
          startDate: rangeDate(this.request.startDate),
          endDate: rangeDate(this.request.endDate)
        })
      );
    }
  }

  @computed get daysOff() {
    return this.daysOffJson.map(json => new DayOffViewModel(json));
  }

  @computed get totalCalendarDays() {
    return moment(this.request.endDate).diff(this.request.startDate, 'days') + 1;
  }

  @computed get totalCostInDays() {
    return Math.round(_.sumBy(this.daysOff, 'days') * 100) / 100;
  }

  @computed get totalNonWorkDays() {
    return _.filter(this.daysOff, 'hasCompanyDayOff').length;
  }

  @computed get includedHolidays() {
    return _.filter(this.daysOff, 'hasHoliday').map(d => d.holidayName);
  }

  @computed get exceededHours() {
    return _.chain(this.conflictingDates)
      .map((day) => {
        return {
          date: day.date,
          remainingHours: parseFloat(day.remainingHours),
          requestedHours: this.requestedHours(day.date)
        };
      })
      .filter(day => day.requestedHours > day.remainingHours)
      .value();
  }

  @computed get waitingPeriodFinalDay() {
    return moment(this.employee.startDate).add(this.currentTypePolicy.waitPeriod, 'days');
  }

  @computed get showProbationWarning() {
    if (this.currentTypePolicy.waitPeriod === 0) return false;

    const requestWithinWaitingPeriod = this.waitingPeriodFinalDay.isSameOrAfter(moment(this.request.startDate));

    return requestWithinWaitingPeriod && this.userIsManagerOrAdmin;
  }

  @computed get summaryItems() {
    let items = [
      {
        title: t('employees.profile.time_off.request.summary.Duration'),
        value: t('employees.profile.time_off.request.summary.CALENDAR_DAYS', {days: this.totalCalendarDays})
      }
    ];
    if (this.totalNonWorkDays) {
      items.push({
        title: t('employees.profile.time_off.request.summary.Included Non-work Days'),
        value: t('employees.profile.time_off.request.summary.COST_IN_DAYS', {days: this.totalNonWorkDays})
      });
    }
    if (this.includedHolidays.length) {
      items.push({
        title: t('employees.profile.time_off.request.summary.Included Holidays'),
        value: this.includedHolidays.join(', ')
      });
    }
    items.push({
      title: t('employees.profile.time_off.request.summary.Total Cost'),
      value: t('employees.profile.time_off.request.summary.COST_IN_DAYS', {days: this.totalCostInDays})
    });

    return items;
  }

  @computed get usageItems() {
    if (!this.forecastedBalance) {
      return [];
    }

    return [
      {
        type : t('employees.profile.time_off.request.forecast.Available'),
        before: this.forecastedBalance.unlimited ?
                  t('employees.profile.time_off.request.forecast.Unlimited') :
                  t('employees.profile.time_off.request.forecast.DAYS', {days: this.forecastedBalance.availableDays}),
        after: this.forecastedBalance.unlimited ?
                 t('employees.profile.time_off.request.forecast.Unlimited') :
                 t('employees.profile.time_off.request.forecast.DAYS', {days: this.forecastedAvailableDays}),
        before_is_negative: !this.forecastedBalance.unlimited && this.forecastedBalance.availableDays < 0,
        after_is_negative: !this.forecastedBalance.unlimited && this.forecastedAvailableDays < 0
      },
      {
        type: t('employees.profile.time_off.request.forecast.Used'),
        before: t('employees.profile.time_off.request.forecast.DAYS', {days: this.forecastedBalance.usedDays}),
        after: t('employees.profile.time_off.request.forecast.DAYS', {days: this.forecastedBalance.usedDays}),
        before_is_negative: false,
        after_is_negative: false
      },
      {
        type : t('employees.profile.time_off.request.forecast.Scheduled'),
        before: t('employees.profile.time_off.request.forecast.DAYS', {days: this.forecastedBalance.scheduledDays}),
        after: t('employees.profile.time_off.request.forecast.DAYS', {days: this.forecastedScheduledDays}),
        before_is_negative: false,
        after_is_negative: false
      }
    ];
  }

  @computed get sortedTypes() {
    return _.sortBy(this.types, 'order');
  }

  @computed get currentTypePolicy() {
    return _.find(this.typePolicies, {
      type: { id: this.request.timeOffType.id }
    });
  }

  @computed get loadingRequestSummary() {
    return this.fetchingRequestedDays || this.fetchingConcurrentRequests || this.forecastingAccount || this.fetchingBlockedDates;
  }

  @computed get blockedDatesEffective() {
    return !_.isEmpty(this.blockedDates) && !this.userIsManagerOrAdmin;
  }

  @computed get canSubmitRequest() {
    return this.request.isNew || this.request.canPatch;
  }

  @computed get showNegativeBalanceWarning() {
    return !this.forecastedBalance.unlimited && this.forecastedAvailableDays < 0;
  }

  @computed get forecastedAvailableDays() {
    if (!!this.forecastedBalance) {
      return Math.round((this.forecastedBalance.availableDays - this.totalCostInDays) * 100) / 100;
    } else {
      return 0;
    }
  }

  @computed get forecastedScheduledDays() {
    if (!!this.forecastedBalance) {
      return Math.round((this.forecastedBalance.scheduledDays + this.totalCostInDays) * 100) / 100;
    } else {
      return 0;
    }
  }

  @computed get userIsManagerOrAdmin() {
    return auth.hasAccess('::MANAGE_TIME_OFF') || this.employee.accessLevel('::TIME_OFF') === 'w';
  }

  requestedHours(date) {
    return parseFloat(_.find(this.request.daysOff, dayOff => date === dayOff.date).cost);
  }
}

export default RequestTimeOffContainerState;
