import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const RemoveManualAdjustmentModal = observer(({modalOpen, uiState}) => {
  return (
    <AlertModal
      mode='danger'
      size='sm'
      header='employees.profile.time_off.Delete Manual Adjustment'
      body='employees.profile.time_off.Are you sure you want to delete this manual adjustment?'
      onHide={() => uiState.closeRemoveManualAdjustmentModal()}
      isOpen={modalOpen}
    >
      <ModalDangerButtons
        saveCaption={'Delete'}
        onCancel={() => uiState.closeRemoveManualAdjustmentModal()}
        onSave={() => uiState.deleteManualAdjustment()}
      />
    </AlertModal>
  );
});

export default RemoveManualAdjustmentModal;
