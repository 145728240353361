import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const RemoveTimeOffRequestModal = observer(({modalOpen, uiState}) => {
  return (
    <AlertModal
      mode='danger'
      size='sm'
      header='employees.profile.time_off.Delete Time Off Request'
      subHeader='employees.profile.time_off.Are you sure you want to delete this time off request?'
      body='employees.profile.time_off.The requested time off will be refunded.'
      onHide={() => uiState.closeRemoveTimeOffRequestModal()}
      isOpen={modalOpen}
    >
      <ModalDangerButtons
        saveCaption={'Delete'}
        onCancel={() => uiState.closeRemoveTimeOffRequestModal()}
        onSave={() => uiState.deleteTimeOffRequest()}
      />
    </AlertModal>
  );
});

export default RemoveTimeOffRequestModal;
